import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import { Link } from 'gatsby'
import he from 'he'

class BlogCard extends Component {

  render() {
    let { title, slug, acf, excerpt, featuredImage, categories } = this.props
    return (
      <div className="blogs__item">
        <Fade bottom distance="40px">
          <div>
            <div className="blogs__image">
              {featuredImage && <img src={featuredImage?.node?.localFile.childImageSharp.fixed.src} alt={title} />}
            </div>
            <div className="blogs__wrap">
              <div className="blogs__meta">
                {categories && <span className="blogs__category">{he.decode(categories.nodes[0].name)}</span>}
                <span className="blogs__time">{acf.readTime}</span>
              </div>
              <h3>{he.decode(title)}</h3>
              <div className="blogs__excerpt" dangerouslySetInnerHTML={{ __html: excerpt }} />
            </div>
          </div>
        </Fade>
        <Link className="blogs__link" to={`/${slug}`}></Link>
      </div>
    )
  }
}

export default BlogCard
