import React, { Component } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import encode from '../utils/encode'

class Covid extends Component {

  state = {
    first_name: '',
    last_name: '',
    email: '',
    company: '',
    company_size: '',
    subscribe: false,
    formActive: false,
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state
      })
    })
      .then(() => this.setState({result: 'success'}))
      .catch(error => this.setState({result: 'fail'}))

    if ( this.state.subscribe === 'on' ) {
      addToMailchimp(this.state.email, {
        FNAME: this.state.frist_name,
        LNAME: this.state.last_name
      })
    }
  }

  _showForm = () => {
    this.setState({ formActive: true })
  }

  render() {
    let { covidGuideTitle, covidGuideConfirmation, covidGuideImage } = this.props
    let { formActive } = this.state
    return (
      <section className='download download--alt'>
        <div className='download__inner'>
          <div className='download__image'>
            { covidGuideImage && <img src={covidGuideImage.localFile.childImageSharp.fixed.src} alt="Seiva - Download PDF" /> }
          </div>
          <div className={formActive ? 'download__content active' : 'download__content'}>
            <div className='download__initial'>
              <h2>{covidGuideTitle}</h2>
              <button className='btn btn--border' onClick={() => this._showForm()}>Get guide</button>
            </div>
            <div className='download__form'>
              { this.state.result === 'success' &&
                <h3 dangerouslySetInnerHTML={{ __html: covidGuideConfirmation }} />
              }
              { this.state.result !== 'success' &&
                <form
                  className='form form--download'
                  name='stimulus'
                  method='post'
                  onSubmit={this.handleSubmit}>
                  <input type='hidden' name='form-name' value='download' />
                  <div className='form__row'>
                    <input type='text' name='first_name' placeholder='First Name' onChange={this.handleChange} required />
                  </div>
                  <div className='form__row'>
                    <input type='text' name='last_name' placeholder='Last Name' onChange={this.handleChange} required />
                  </div>
                  <div className='form__row'>
                    <input type='email' name='email' placeholder='Email' onChange={this.handleChange} required />
                  </div>
                  <div className='form__row'>
                    <input type='text' name='company' placeholder='Company name' onChange={this.handleChange} required />
                  </div>
                  <div className='form__row'>
                    <input type='text' name='company_size' placeholder='Company size (optional)' onChange={this.handleChange} />
                  </div>
                  <div className='form__row form__row--checkbox'>
                    <label htmlFor='subscribe'>
                      <input type='checkbox' id='subscribe' name='subscribe' onChange={this.handleChange} />
                      Subscribe to our newsletter
                    </label>
                  </div>
                  <div className='form__row form__row--submit'>
                    <button className='btn btn--border' type='submit'>Get guide</button>
                  </div>
                </form>
              }
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Covid
