import React, { Component } from "react"
import { StaticQuery, graphql } from 'gatsby'
import he from 'he'

import Cta from "../components/cta"
import BlogCard from "../components/cards/blog"
import Covid from "../components/covid"
import DownloadGuideAlt from "../components/download-guide-alt"

import Seo from '../components/seo'

class InsightsPage extends Component {

  render() {
    let { posts, page } = this.props.data

    return (
      <>
        <Seo title={he.decode(page.seo.title)} bodyClass={page.slug} description={page.seo.metaDesc} />
        <section className="blogs">
          <div className="blogs__inner container">
            <h1>Insights</h1>
            <div className="blogs__items">
              { posts.edges.map((el, i) => {
                if (i === 3) {
                  return (
                    <>
                      <Covid covidGuideTitle={page.acf.covidGuideTitle} covidGuideConfirmation={page.acf.covidGuideConfirmation} covidGuideImage={page.acf.covidGuideImage} /> 
                      <BlogCard key={i} {...el.node} />
                    </>
                  )
                } else {
                  return (
                    <BlogCard key={i} {...el.node} />
                  )
                }
              })}
            </div>
            <DownloadGuideAlt downloadGuideTitle={page.acf.downloadGuideTitle} downloadGuideConfirmation={page.acf.downloadGuideConfirmation} downloadGuideImage={page.acf.downloadGuideImage} />
          </div>
        </section>
        <Cta title={`<p>So tell us, what is *it you’re after? <span>We look forward to learning what *it is that will make you happier.</span></p>`} buttonLink='/contact-us/' buttonText='Connect' />
      </>
    )
  }
}

const BlogData = (props) => (
  <StaticQuery
    query={graphql`
      query {
        page: wpPage(databaseId: {eq: 11}) {
          title
          slug
          seo {
            title
            metaDesc
            opengraphTitle
            opengraphDescription
            opengraphImage {
              altText
              localFile {
                childImageSharp {
                  original {
                    src
                    width
                    height
                  }
                }
              }
            }
          }
          acf {
            downloadGuideImage {
              localFile {
                childImageSharp {
                  fixed (width: 1200) {
                    src
                  }
                }
              }
            }
            downloadGuideTitle
            downloadGuideConfirmation
            covidGuideImage {
              localFile {
                childImageSharp {
                  fixed (width: 1200) {
                    src
                  }
                }
              }
            }
            covidGuideTitle
            covidGuideConfirmation
          }
        }
        posts: allWpPost {
          edges {
            node {
              title
              slug
              categories {
                nodes {
                  name
                  slug
                }
              }
              excerpt
              featuredImage {
                node {
                  localFile {
                    childImageSharp {
                      fixed (width: 800) {
                        src
                      }
                    }
                  }
                }
              }
              acf {
                readTime
              }
            }
          }
        }
      }
    `}
    render={data => <InsightsPage data={data} {...props} />}
  />
)

export default BlogData